<template>
  <section v-bind:style="styleObject" class="wrapper">
    <Tp1
      v-if="+tid === 1"
      :content="contentList"
      :author="authorInfo"
      :qrcode="qrcode"
      :textSize="textSize"
      :textSpacing="textSpacing"
      :lineGap="lineGap"
    />
    <Tp2
      v-if="+tid === 2"
      :content="contentList"
      :author="authorInfo"
      :image="image"
      :blur="blur"
      :qrcode="qrcode"
      :textSize="textSize"
      :textSpacing="textSpacing"
      :lineGap="lineGap"
      position="leftTop"
    />
    <Tp2
      v-if="+tid === 3"
      :content="contentList"
      :author="authorInfo"
      :image="image"
      :blur="blur"
      :qrcode="qrcode"
      :textSize="textSize"
      :textSpacing="textSpacing"
      :lineGap="lineGap"
      position="rightTop"
    />
    <Tp2
      v-if="+tid === 4"
      :content="contentList"
      :author="authorInfo"
      :image="image"
      :blur="blur"
      :qrcode="qrcode"
      :textSize="textSize"
      :textSpacing="textSpacing"
      :lineGap="lineGap"
      position="center"
    />
    <Tp2
      v-if="+tid === 5"
      :content="contentList"
      :author="authorInfo"
      :image="image"
      :blur="blur"
      :qrcode="qrcode"
      :textSize="textSize"
      :textSpacing="textSpacing"
      :lineGap="lineGap"
      position="leftBottom"
    />
    <Tp2
      v-if="+tid === 6"
      :content="contentList"
      :author="authorInfo"
      :image="image"
      :blur="blur"
      :qrcode="qrcode"
      :textSize="textSize"
      :textSpacing="textSpacing"
      :lineGap="lineGap"
      position="rightBottom"
    />
    <Tp7
      v-if="+tid === 7"
      :content="contentList"
      :author="authorInfo"
      :image="image"
      :blur="blur"
      :qrcode="qrcode"
      :textSize="textSize"
      :textSpacing="textSpacing"
      :lineGap="lineGap"
    />
    <Tp8
      v-if="+tid === 8"
      :content="contentList"
      :author="authorInfo"
      :image="image"
      :blur="blur"
      :qrcode="qrcode"
      :textSize="textSize"
      :textSpacing="textSpacing"
      :lineGap="lineGap"
    />
    <Tp9
      v-if="+tid === 9"
      :content="contentList"
      :author="authorInfo"
      :image="image"
      :blur="blur"
      :qrcode="qrcode"
      :textSize="textSize"
      :textSpacing="textSpacing"
      :lineGap="lineGap"
    />
    <Tp10
      v-if="+tid === 10"
      :content="contentList"
      :author="authorInfo"
      :image="image"
      :blur="blur"
      :qrcode="qrcode"
      :textSize="textSize"
      :textSpacing="textSpacing"
      :lineGap="lineGap"
      :bgc="bgc"
    />
    <Tp11
      v-if="+tid === 11"
      :content="contentList"
      :author="authorInfo"
      :image="image"
      :blur="blur"
      :qrcode="qrcode"
      :textSize="textSize"
      :textSpacing="textSpacing"
      :lineGap="lineGap"
    />
    <Tp12
      v-if="+tid === 12"
      :content="contentList"
      :author="authorInfo"
      :image="image"
      :blur="blur"
      :qrcode="qrcode"
      :textSize="textSize"
      :textSpacing="textSpacing"
      :lineGap="lineGap"
    />
  </section>
</template>
<script>
import Tp1 from "./poster/tp1.vue";
import Tp2 from "./poster/tp2.vue";
import Tp7 from "./poster/tp7.vue";
import Tp8 from "./poster/tp8.vue";
import Tp9 from "./poster/tp9.vue";
import Tp10 from "./poster/tp10.vue";
import Tp11 from "./poster/tp11.vue";
import Tp12 from "./poster/tp12.vue";
import compact from "lodash.compact";
import { defineComponent } from "vue";

export default defineComponent({
  name: "poster",
  components: {
    Tp1,
    Tp2,
    Tp7,
    Tp8,
    Tp9,
    Tp10,
    Tp11,
    Tp12,
  },
  props: {
    bgc: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    author: {
      type: String,
      required: false,
    },
    blur: {
      type: String || Number,
      required: true,
      default: 0,
    },
    image: {
      type: String,
      required: false,
    },
    qrcode: {
      type: String,
      required: false,
    },
    tid: {
      type: String,
      required: true,
    },
    font: {
      type: String,
      required: false,
    },
    textSize: {
      type: String,
      required: true,
    },
    textSpacing: {
      type: String,
      required: true,
    },
    lineGap: {
      type: String,
      required: true,
    },
  },
  computed: {
    styleObject() {
      const bgc = this.bgc === "transparent" ? "transparent" : `#${this.bgc}`;
      let obj = {
        backgroundColor: bgc,
        color: `#${this.color}`,
      }
      if (this.font && this.font !== 'defaultFont') {
        obj = {
          ...obj,
          fontFamily: this.font,
        }
      }
      return obj
    },
    contentList() {
      return (this.content && this.content.split("|")) || [];
    },
    authorInfo() {
      return compact([this.title, this.author]).join(" • ");
    },
  },
});
</script>
<style>
.wrapper {
  overflow: hidden;
  width: 414px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #000;
}
</style>
