<template>
  <section class="tp1" id="poster">
    <div class="content-wrapper">
      <div class="author">
      {{ author }}
      </div>
      <div class="content">
        <div
          v-for="item in content"
          :key="item"
          class="contentText"
          :style="contentStyle"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <Qrcode
      v-if="qrcode"
      :qrcode="qrcode"
    />
  </section>
</template>
<script>
import { defineComponent } from "vue";
import Qrcode from "./qrcode.vue";
export default defineComponent({
  components: {
    Qrcode,
  },
  props: {
    content: {
      type: Array,
      required: true,
    },
    author: {
      type: String,
      required: false,
    },
    qrcode: {
      type: String,
      required: false,
    },
     textSize: {
      type: String,
      required: true,
    },
    textSpacing: {
      type: String,
      required: true,
    },
    lineGap: {
      type: String,
      required: true,
    },
  },
  computed: {
    contentStyle() {
      return {
        fontSize: `${this.textSize}px`,
        letterSpacing: `${this.textSpacing}px`,
        lineHeight: `${this.lineGap}em`
      };
    },
  },
});
</script>
<style scoped>
.tp1 {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.content-wrapper {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  height: 500px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
}

.content {
  font-size: 30px;
  flex: 1;
  height: 100%;
  writing-mode: vertical-rl;
  text-align: start;
}

.contentText {
  text-align: start;
  writing-mode: vertical-rl;
  line-height: 1.4em;
}

.author {
  font-size: 22px;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  writing-mode: vertical-rl;
}
</style>
