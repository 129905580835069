import { createRouter, createWebHistory } from "vue-router";

import Poster from "./components/Poster.vue";
const routerHistory = createWebHistory();

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: "/",
      component: Poster,
      props: (route) => ({
        bgc: route.query.bgc,
        content: route.query.content,
        title: route.query.title,
        author: route.query.author,
        color: route.query.color,
        font: route.query.font,
        blur: route.query.blur,
        image: route.query.image,
        tid: route.query.tid,
        qrcode: route.query.qrcode,
        textSize: route.query.textSize, // 诗词的字体大小
        textSpacing: route.query.textSpacing, // 诗词字符间距
        lineGap: route.query.lineGap, // 诗词行间距, 单位em
      }),
    },
  ],
});

export default router;
