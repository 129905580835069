<template>
  <section class="qrcode">
    <img class="icon" :src="imgUrl" alt="qrcode">
    <div class="content">
      <div class="top">诗耍，发现旅游胜地的诗！</div>
      <div class="btm">诗耍，带着诗歌去玩耍！</div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    qrcode: {
      type: String,
      required: false,
    },
  },
  computed: {
    imgUrl() {
      return`${process.env.BASE_URL}/tpl/${this.qrcode}.png`
    },
  }
});
</script>
<style scoped>
.qrcode {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  font-family: TChinese, SimSun, FangSong, STSong, STZhongsong, LiSu, KaiTi,
    -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Segoe UI,
    Arial, Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft Yahei",
    sans-serif;
}

.icon {
  width: 80px;
  height: 80px;
  vertical-align: middle;
}

.content {
  color: #000;
  margin-left: 20px;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
}

.top,
.btm {
  letter-spacing: 2px;
}

.top {
  margin-bottom: 10px;
}
</style>
