<template>
  <section class="tp10" id="poster">
    <div class="image-wrapper">
      <img
        :src="imgUrl"
        alt="posterbg"
        class="image"
        :style="imageStyle"
      />
      <div
        class="content-wrapper"
        :style="contentBgc"
      >
        <div class="content">
          <div
            v-for="item in content"
            :key="item"
            class="contentText"
            :style="contentStyle"
          >
            {{ item }}
          </div>
        </div>
        <div class="author">
          {{ author }}
        </div>
      </div>
    </div>
    <Qrcode
      v-if="qrcode"
      :qrcode="qrcode"
    />
  </section>
</template>
<script>
import { defineComponent } from "vue";
import Qrcode from "./qrcode.vue";
export default defineComponent({
  components: {
    Qrcode,
  },
  props: {
    content: {
      type: Array,
      required: true,
    },
    author: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    qrcode: {
      type: String,
      required: false,
    },
    blur: {
      type: Number,
      required: false,
    },
    textSize: {
      type: String,
      required: true,
    },
    textSpacing: {
      type: String,
      required: true,
    },
    lineGap: {
      type: String,
      required: true,
    },
    bgc: {
      type: String,
      required: true,
    },
  },
  computed: {
    imgUrl() {
      let suffix = 'png'
      const jpgs = ['b12291f70d24398a773b865c2bc56ac2', 'b77aa4075d64e6cb43cee7f9874cd8d6']

      if (jpgs.includes(this.image)) {
        suffix = 'jpg'
      }

      console.log('process.env.BASE_URL: ', process.env.BASE_URL)
      // const url = 'https://poem.fengxiaomao.com/2f472ce78b9294569de51ef263e25711?e=4734147487&token=C4V1LUiqHJXmvGKUjGWOH8oOmZ1KCeFOWWr-C9CX:Jxconpnpx9m2RxpIVhHY3ge7yws='
      const url = `${process.env.BASE_URL}/tpl/${this.image}.${suffix}`
      return url
    },
    imageStyle() {
      return {
        filter: `blur(${(this.blur || 0) / 10}px)`,
      };
    },
    contentBgc() {
      const bgc = this.bgc === "transparent" ? "transparent" : `#${this.bgc}`;

      return {
        backgroundColor: bgc || '#ffffff',
      };
    },
    contentStyle() {
      return {
        fontSize: `${this.textSize}px`,
        letterSpacing: `${this.textSpacing}px`,
        lineHeight: `${this.lineGap}em`,
      };
    },
  },
});
</script>
<style scoped>
.tp10 {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
}
.image-wrapper {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
}
.image {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.content-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  padding: 20px 10px;
  box-sizing: border-box;
  width: calc(100% - 40px);
  background-color: #ffffff;
}

.content {
  font-size: 26px;
  text-align: center;
}

.contentText {
  line-height: 1.4em;
}

.author {
  font-size: 22px;
  margin-top: 1.5em;
  text-align: right;
}
</style>
