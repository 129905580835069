<template>
  <section class="tp2" id="poster">
    <div class="image-wrapper">
      <img
        :src="imgUrl"
        alt="posterbg"
        class="image"
        :style="imageStyle"
      />
      <div :class="[[position], 'contentWrapper']">
        <div class="poemVertical">
          <div class="poem">
            <div
              v-for="(item, i) in content"
              :key="item"
              class="text"
              :style="[contentStyle, {
                textIndent: position !== 'center' && i > 0 ? `${Number(textSize) + Number(textSpacing)}px` : 0,
              }]"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Qrcode
      v-if="qrcode"
      :qrcode="qrcode"
    />
  </section>
</template>
<script>
import { defineComponent } from "vue";
import Qrcode from "./qrcode.vue";
export default defineComponent({
  components: {
    Qrcode,
  },
  props: {
    content: {
      type: Array,
      required: true,
    },
    author: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    qrcode: {
      type: String,
      required: false,
    },
    blur: {
      type: Number,
      required: false,
    },
    position: {
      type: String,
      required: true,
    },
    textSize: {
      type: String,
      required: true,
    },
    textSpacing: {
      type: String,
      required: true,
    },
    lineGap: {
      type: String,
      required: true,
    },
  },
  computed: {
    imgUrl() {
      let suffix = 'png'
      const jpgs = ['b12291f70d24398a773b865c2bc56ac2', 'b77aa4075d64e6cb43cee7f9874cd8d6']

      if (jpgs.includes(this.image)) {
        suffix = 'jpg'
      }

      console.log('process.env.BASE_URL: ', process.env.BASE_URL)
      // const url = 'https://poem.fengxiaomao.com/2f472ce78b9294569de51ef263e25711?e=4734147487&token=C4V1LUiqHJXmvGKUjGWOH8oOmZ1KCeFOWWr-C9CX:Jxconpnpx9m2RxpIVhHY3ge7yws='
      const url = `${process.env.BASE_URL}/tpl/${this.image}.${suffix}`
      console.log('url: ', url)
      return url
    },
    imageStyle() {
      return {
        filter: `blur(${(this.blur || 0) / 10}px)`,
      };
    },
    contentStyle() {
      return {
        fontSize: `${this.textSize}px`,
        letterSpacing: `${this.textSpacing}px`,
        lineHeight: `${this.lineGap}em`,
      };
    },
  },
});
</script>
<style scoped>
.tp2 {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
}
.image-wrapper {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
}
.image {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.contentWrapper {
  position: absolute;
  padding: 20px;
  box-sizing: border-box;
}
.leftTop,
.leftBottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.rightTop,
.rightBottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.leftTop {
  left: 0;
  top: 0;
}

.leftBottom {
  left: 0;
  bottom: 0;
}

.rightTop {
  right: 0;
  top: 0;
}

.rightBottom {
  right: 0;
  bottom: 0;
}

.center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.poemVertical {
  margin: 0 auto;
}
.poem {
  writing-mode: vertical-rl;
}
.text {
  letter-spacing: 6px;
  writing-mode: vertical-rl;
  line-height: 1.2em;
  font-weight: normal;
  font-size: 26px;
}
</style>
