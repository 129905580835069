<template>
  <router-view></router-view>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
});
</script>

<style>
@import "./assets/fonts/font.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  height: 100%;
  font-size: 16px;
}
body {
  position: relative;
  zoom: 1;
  height: 100%;
}
#app {
  font-family: TChinese, SimSun, FangSong, STSong, STZhongsong, LiSu, KaiTi,
    -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Segoe UI,
    Arial, Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft Yahei",
    sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
}
</style>
